/* App.css */
body {
    font-family: 'Arial', sans-serif;
    background-color: #1e1e1e;
    margin: 0;
    height: 100vh; /* Make sure the body takes full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-container {
    text-align: center;
    padding: 40px;
    background-color: #2e2e2e;
    max-width: 900px;
    margin: 0 auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    color: #f5f5f5;
}

.app-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #e1e1e1;
}

.app-container p {
    font-size: 1.2em;
    color: #c0c0c0;
    line-height: 1.6;
}

.app-container ul {
    text-align: left;
    margin-top: 20px;
}

.app-container ul li {
    font-size: 1.1em;
    margin-bottom: 10px;
}

.logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
}

.features-list {
    list-style-type: none;
    padding: 0;
}

.features-list li::before {
    content: '✔';
    color: #27ae60;
    padding-right: 10px;
}

footer {
    margin-top: 50px;
    font-size: 0.9em;
    color: #999;
}

@media (max-width: 600px) {
    .app-container {
        padding: 20px;
    }
}
